import React from 'react';
import {COLORS} from '../constants/colors';
import styled from 'styled-components';
import { motion } from "framer-motion";
import {FadeIn} from './animations';


const variants = {
  init: {
    height: 0
  },
  open: props => ({
    height: props.height || "90%",
    transition: {
        when: "beforeChildren",
        duration: props.duration || 2000,
        delay: props.delay || 0,
        type: 'spring',
        stiffness: 200,
        damping: 15
    }
  })
};

const BannerAnimation = React.forwardRef((props,ref)=> (<motion.div
  initial="init"
  custom={props}
  variants={variants}
  {...props}
  ref={ref}
/>));


const StyledBanner = styled(BannerAnimation)`
width: ${props=>props.width};
height: ${props=>props.height};
box-sizing: content-box;
padding-top: 15px;
position: relative;
background: ${props=>props.background};
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
text-align:center;
box-shadow: -5px 0px 7px -3px rgba(0,0,0,0.4);

&:before{
  content: '';
  position: absolute;
  left: 0;
  bottom: -13px;
  width: 100%;
  height: 0;
  border-bottom: 13px solid transparent;
  border-left: 55px solid ${props=> props.flair || props.background};
  border-right: 55px solid ${props=> props.flair || props.background};
}
`;
export default class Banner extends React.Component {

  static defaultProps = {
    width: "120px",
    height: "90%",
    background: COLORS.blue,
    flag: null, //array of values to linear gradient down instead of background
    className: "",
    animateWhen: null, //'viewport' or 'init' or null
    delay: 0
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      width: 0,
      height: 0,
      top: 0,
      animate: this.props.animateWhen ? "init" : "open"
    };
    this.scrollcb = () => { this.animate(); };
  }

  componentDidMount() {
    const { current } = this.ref;
    if (this.props.animateWhen === 'viewport') {
      window.addEventListener('scroll', this.scrollcb);
    }
    else if(this.props.animateWhen === 'init'){
      this.setState({animate:'open'});
    }
  }

  componentDidUpdate(prevProps) {

    this.updateBounds();
  }

  componentWillUnmount() {
    if (this.props.animateWhen === 'viewport') {
      window.removeEventListener('scroll', this.scrollcb);
    }
  }

  updateBounds(callback) {
    const { current } = this.ref;
    const { height, width, top } = current.getBoundingClientRect();
    if (height !== this.state.height || width !== this.state.width || top !== this.state.top) {
      this.setState({ width, height, top }, callback); return;
    }
    if (callback != null) { callback(); }
  }

  animate() {
    const { scrollY, innerHeight } = window;
    const viewportBottom = scrollY + innerHeight;
    const whenInViewport = this.state.top + 600;
    this.setState({ animate: viewportBottom <= whenInViewport ? 'init' : 'open' });
  }

  render() {
    var background = this.props.background;
    var flair = null;
    if(this.props.flag){
      background = this.props.flag[0];
      if(this.props.flag.length > 1){
        background = `linear-gradient(to bottom,${this.props.flag.join(',')})`
        flair = this.props.flag[this.props.flag.length-1];
      }
    }
    return (
          <StyledBanner 
          {...this.props}
          background={background}
          flair={flair}
          animate={this.state.animate}
          ref={this.ref}
          whowhatnow="1"
          >
            <FadeIn>{this.props.children}</FadeIn>
          </StyledBanner>
    );
  }
}


