import '../App.css';
import './Resizable.css';
import React from 'react';
import { COLORS } from '../constants/colors';
import CircleButton from './CircleButton';
import {FadeFrom} from '../ux/animations';

export default class Rotatable extends React.Component {

  static defaultProps = {
    increment: 30,
    angle: 0,
    btnColor: COLORS.yellow,
    onRotate: () =>{}
  };


  constructor(props) {
    super(props);
    this.state = {
        angle:this.props.angle
    };
  }

 

  render() {
    return (
        <div className="position-absolute">
            <div style={{
                    transform:"rotate("+this.state.angle+"deg)",
                    transition: "transform " + 10*this.props.increment + "ms"
                }}>
                {this.props.children}
            </div>
            <FadeFrom from={{y:-15}} show={this.props.showControls}>
            <div className="rotatable-controls" data-html2canvas-ignore>
            <CircleButton
                onClick={this.rotate}
                color={this.props.btnColor}>
                    <span className="fas fa-sync-alt"></span>
                </CircleButton>
            </div>
            </FadeFrom>
        </div>
      );
  }

  rotate = (e) =>{
    let newAngle = this.state.angle + this.props.increment;
    this.setState({angle:(newAngle)});
    this.props.onRotate(newAngle);
  }

}

