import React from 'react';
import styled,{keyframes} from 'styled-components';
import {COLORS} from '../constants/colors';

const Rainbow = keyframes`
    0%   { color: ${COLORS.red}; } 
    14%  { color: ${COLORS.orange}; }
    28%  { color: ${COLORS.yellow}; }
    42%  { color: ${COLORS.green}; }
    56%  { color: ${COLORS.blue}; }
    70% { color: ${COLORS.purple}; }
    84% { color: ${COLORS.pink}; }
    100% { color: ${COLORS.red}; }
`;

const AboutAppWrapper = styled.a`
    width:100%;
    text-align:right;
    padding-top:1rem;
    font-size:1.75rem;
    animation: ${Rainbow} 20s infinite;
    text-shadow:0px 0px 4px rgb(0,0,0,0.5);
    transition: text-shadow 400ms;
    &:hover{
        text-shadow:0px 0px 6px rgb(0,0,0,0.8);
    }
`;

const Logo = styled.span`
    font-family: 'Marck Script', cursive;
`;



const AboutApp = (props) => (
        
        <AboutAppWrapper className="mt-auto" onClick={props.toggleSplash}>
            About&nbsp;<Logo>Pride Stripes</Logo>
        </AboutAppWrapper>
        
);

export default AboutApp;