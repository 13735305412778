import styled from 'styled-components';
import * as chromatism from 'chromatism';

const Toggle = styled.span`
    position:relative;
    border-radius: 1.25rem;
    width:4rem;
    height:2.5rem;
    display:inline-block;
    cursor:pointer;
    transition: box-shadow 300ms;
    margin-left:0.25rem;
    margin-right:0.25rem;
    box-shadow: 0px 0px 7px 0px inset rgba(0,0,0,0.49);
    background-color: ${props=> chromatism.brightness(20,props.color).hex};

    &:hover{
        box-shadow: 0px 0px 7px 0px inset rgba(0,0,0,0.69);
    }

    &:after{
        content:'';
        position:absolute;
        height:100%;
        width:2.5rem;
        z-index:1;
        left:0;
        border-radius:50%;
        background-color:rgba(0,0,0,0.5);
        transition:left 500ms, background-color 600ms;
        box-shadow: 0px 0px 7px 0px inset rgba(0,0,0,0.5);

        ${props=> props.enable && 
            `
            left:35%;      
            background-color:rgba(255,255,255,0.5);
            `
        }
    }
`;
export default Toggle;