
import React from 'react';
import Banner from './Banner';
import { FLAG_PRESETS } from '../constants/flag-presets';
import { COLORS } from '../constants/colors';
import { randomValue } from '../util';
import styled from 'styled-components';
import { FadeIn } from './animations';
import packageJson from './../../package.json';

const BANNER_HEIGHT = 275;

const SplashWrapper = styled.div`
    position:absolute;
    z-index:9000;
    left:0;
    right:0;
    height:100vh;
    background:#333333;
    color:white;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
`;

const BannerWrapper = styled.div`
    height:${BANNER_HEIGHT+30}px;
    text-align:center;
    position:relative;
    width:100%;
`;

const Logo = styled(FadeIn)`
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;
    z-index:100;
    font-family: 'Marck Script', cursive;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
    font-size:3rem;
    color: ${props => props.color}
    margin:0 2rem;
`;

const GlowLink = styled.a`
    color:rgb(255,255,255,0.8);
    transition: text-shadow 300ms;
    text-align:center;
    font-size:1.33rem;
    &:hover{
        color:rgb(255,255,255,0.8);
        text-shadow:0px 0px 4px rgba(255, 255, 255, 0.8);
        text-decoration: none;
    }
    &.h3{font-size:2rem;}
`;
const TitledLink = styled(GlowLink)`
    &:hover{
        .title{
            opacity:1;
        }
    }
    .title{
        opacity:0;
        transition: opacity 300ms;
    }
`;
export default class Splash extends React.Component {

    static defaultProps = {
        showFeedback: false,
    };
    constructor(props) {
        super(props);
        this.state = {
            flag: randomValue(FLAG_PRESETS),
            color: COLORS.purple,
            version: packageJson.version
        };
    }

    componentDidMount() {
    }
    render() {
        const { showFeedback } = this.props;
        const controlFade = showFeedback ? { opacity: 1, transition: { delay: 2, duration: 2 } } : {};
        return (
            <SplashWrapper>
                <FadeIn className="mt-4" animate={{ opacity: 1 }}>
                    <div class="text-center">
                        <div class="h5">brought to you by...</div>
                        <GlowLink className="h3">Your Name Here</GlowLink>
                    </div>
                </FadeIn>
                
                <BannerWrapper>
                    <Banner
                        className="mx-auto"
                        height={`${BANNER_HEIGHT}px`}
                        flag={this.state.flag.strips}
                        animateWhen="init"
                    />
                    <Logo {...this.state}>
                        <div>Pride Stripes</div>
                        <div style={{ fontSize: 13 }}>{this.state.version}</div>
                    </Logo>

                </BannerWrapper>
                
                {showFeedback &&
                    <FadeIn
                        className="h4 mt-4"
                        animate={controlFade}
                    >Identify and Generate Pride Flags</FadeIn>
                }

                <div class="d-flex mb-4">
                    <FadeIn
                        className="text-center"
                        animate={controlFade}
                    >
                        <TitledLink title="Feedback" href="https://twitter.com/intent/tweet?text=%40whirledsol%20%23pridestripes%20Hey%2C" target="_blank">
                            <i className="fas fa-comment-dots fa-2x"></i>
                            <div className="title">Feedback</div>
                        </TitledLink>
                    </FadeIn>
                    <div className="align-self-center text-center mx-5 h5">
                        <div>built by</div>
                        <GlowLink href="http://willrhod.es" target="_blank">@whirledsol</GlowLink>
                    </div>
                    <FadeIn
                        className="text-center"
                        animate={controlFade}
                    >
                        <TitledLink title="Return" onClick={this.props.toggleSplash}>
                            <i className="far fa-play-circle fa-2x"></i>
                            <div className="title">Return...</div>
                        </TitledLink>
                    </FadeIn>
                </div>


            </SplashWrapper>);
    }
}