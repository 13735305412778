import React from 'react';
import { BLING_LIBRARY } from '../constants/blingLibraryItems';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FadeFrom } from '../ux/animations';

const Guides = styled(FadeFrom)`
    position:absolute;
    transform:translateY(-50%);
    top:50%;
    left:50%;
    color:white;
    text-shadow: 2px 2px rgba(0,0,0,0.75);
    font-size:50px;
    opacity:0.90;
`;

const BlingLibraryContainer = styled.div`
    margin:1rem 0;
    overflow:hidden;
    width:100%;
    position:relative;
`;
const BlingLibraryPan = styled(motion.div)`
    width:${props => props.width};
`;

const BlingLibraryItem = styled(motion.div)`
    cursor:pointer;
    display:inline-block;
    width:100px;
    height:100px;
    border:3px solid rgba(255,255,255,0.5);
    transition: box-shadow 200ms, border 200ms;
    padding:5px;
    margin:5px;
    border-radius:5px;
    text-align:center;

    &:hover{
        box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
    }
    &:active{
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    }

    img{
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        max-width:100%;
        height:100%;
    }
`;
export default class BlingLibrary extends React.Component {

    static defaultProps = {
        items: {},
        onAddBling: () => { }
    };


    constructor(props) {
        super(props);
        this.state = {
            items: BLING_LIBRARY || this.props.items,
            width_library: 0,
            width_container: 0,
            guides:"show"
        };
        this.state.width_library = Object.keys(this.state.items).length * 110;
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const width_container = this.containerRef.current.clientWidth;
        this.setState({ width_container })

        setTimeout(()=>{
            this.setState({ guides:"hidden" });
        },1000)
        
    }

    render() {
        const { items, width_library, width_container,guides } = this.state;

        const renderItems = Object.keys(items).map((key, index) => {
            let item = items[key];
            return (
                <BlingLibraryItem
                    key={index}
                    onTap={this.props.onAddBling.bind(this, item)}
                >
                    <img src={item.src} />
                </BlingLibraryItem>);
        });

        const dragConstraints = {
            left: -1 * (width_library - width_container),
            right: 0
        };

        return (
            <BlingLibraryContainer ref={this.containerRef}>
            <Guides animate={guides} from={{x:100}} className="fas fa-hand-point-up"></Guides>
                <BlingLibraryPan width={`${width_library}px`} drag='x' dragConstraints={dragConstraints}>
                    {renderItems}
                </BlingLibraryPan>
            </BlingLibraryContainer>
        );
    }

}


