import React from 'react';
import { FLAG_PRESETS } from '../constants/flag-presets';
import {SettingsSection} from './AppSettings';
import styled from 'styled-components';

const Result = styled.div`
  font-size:1rem;
  margin-bottom: 0.5rem;
  display:flex;
  justify-content:space-between;
`;

const Results = (props) => {
    let matches = props.results.filter(each => each.length > 0 && each[1] < 500);
    matches = matches.length > 4 ? matches.slice(0, 4) : matches;

    let matchesDisplay = matches.map((match, i) => {
      let key = match[0];
      let difference = match[1];
      let name = FLAG_PRESETS[key].name;
      return (
        <Result key={name} style={{ opacity: 1 / (i + 1) }}>
            {name}
            <span className="ml-2">{difference.toFixed(2)}</span>
        </Result>);
    });

    return (
      <SettingsSection>
        <div className="align-self-start">Best Guess</div>
        <div>
            {matchesDisplay}
        </div>
      </SettingsSection>
    );

  };


export default Results;