import React, { useRef,useState,useEffect } from 'react';
import { motion, useMotionValue } from "framer-motion";
import styled from 'styled-components';
import * as chromatism from 'chromatism';
import {saturation,value} from '../constants/colors';
import {COLORS} from '../constants/colors';
import {DIRECTION} from '../constants/flag-presets';

const threshold = 0.15;
const hueSep = (1-threshold)/6;
const slider_size = 24;

const QueerColorPickerWrapper = styled(motion.div)`
    width:100%;
    height:100%;
    position:relative;
    opacity:0;

    background: -moz-linear-gradient( ${props=> props.sideFrom},
        ${COLORS.black} 0%,
        ${COLORS.white} ${threshold*100}%,
        ${COLORS.red} ${100*(threshold + hueSep*0)}%,
        ${COLORS.yellow} ${100*(threshold + hueSep*1)}%,
        ${COLORS.green} ${100*(threshold + hueSep*2)}%,
        ${COLORS.cyan} ${100*(threshold + hueSep*3)}%,
        ${COLORS.blue} ${100*(threshold + hueSep*4)}%,
        ${COLORS.purple} ${100*(threshold + hueSep*5)}%,
        ${COLORS.red} ${100*(threshold + hueSep*6)}%);
    background: -webkit-gradient(linear,
        ${props=> props.sideTo},
        ${props=> props.sideFrom}, 
        color-stop(0%, ${COLORS.black}), 
        color-stop(${threshold*100}%, ${COLORS.white}), 
        color-stop(${100*(threshold + hueSep*0)}%, ${COLORS.red}), 
        color-stop(${100*(threshold + hueSep*1)}%, ${COLORS.yellow}),
        color-stop(${100*(threshold + hueSep*2)}%, ${COLORS.green}),
        color-stop(${100*(threshold + hueSep*3)}%, ${COLORS.cyan}),
        color-stop(${100*(threshold + hueSep*4)}%, ${COLORS.blue}),
        color-stop(${100*(threshold + hueSep*5)}%, ${COLORS.purple}),
        color-stop(${100*(threshold + hueSep*6)}%, ${COLORS.red}));

`;

const SlideWrapperRow = styled(motion.div)`
    height:100%;
    width:${slider_size}px;
    display: flex;
    align-items: center;
    cursor:pointer;
`;
const SlideWrapperCol = styled(motion.div)`
    width:100%;
    height:${slider_size}px;
    display: flex;
    justify-content: center;
    cursor:pointer;
`;
const Slide = styled.div`
    width:${slider_size}px;
    height:${slider_size}px;
    border-radius:50%;
    background: #f2f2f2;
    background: -moz-radial-gradient(center, ellipse cover, ${props=>props.color} 0%, #fcfcfc 100%);
    background: -webkit-radial-gradient(center, ellipse cover, ${props=>props.color} 0%, #fcfcfc 100%);
    background: radial-gradient(ellipse at center, ${props=>props.color} 0%,#fcfcfc 100%);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); 
`;



export const PosToHex = (pos,size) => {
    let per = (pos + slider_size/2)/size;
    if(per <= threshold){
        //grayscale
        const lightness = (per/threshold)*255;
        return chromatism.convert({r:lightness,g:lightness,b:lightness}).hex
    }
    else{
        //hue
        const hue = (per-threshold)/(1-threshold)*360
        return chromatism.convert({h:hue,s:saturation,v:value}).hex
    }
};

export const HexToPos = (color,size) => {
    const hsv = chromatism.convert(color).hsv;
    if(isNaN(hsv.s) || hsv.s == 0){
        
        //grayscale
        return (threshold*hsv.v/100)*size - slider_size/2;
    }
    else{
        //hue
        return (threshold + (hsv.h/360)*(1-threshold))*size - slider_size/2;
    }
};


const QueerColorPicker = (props) => {
    const { onChange, color, direction } = props;
    const [size, setSize] = useState(0);
    const motionValue = useMotionValue(0);
    const constraintsRef = useRef(null);
    const axis = direction === DIRECTION.COL ? 'y':'x';
    const SlideWrapper = direction === DIRECTION.COL ? SlideWrapperCol: SlideWrapperRow;
    const sideFrom = direction === DIRECTION.COL ? 'top' : 'left';
    const sideTo = direction === DIRECTION.COL ? 'bottom' : 'right';
    const dragConstraints = {
        [sideFrom]:-slider_size/2,
        [sideTo]:size-slider_size/2
    };

    useEffect(() => {
        if(constraintsRef.current){
            let sizeAttr = direction === DIRECTION.COL ? 'clientHeight':'clientWidth';
            let newSize = constraintsRef.current[sizeAttr];
            if(newSize !== size ){
                setSize(newSize);
            }
        }
    });

    let position = HexToPos(color,size);
    motionValue.set(position);

    const onDragEnd = (event, info) => {
        let position = info.point[axis];
        if(position > slider_size/-2){
            const color = PosToHex(position,size);
            onChange(color);
        }
    };
    
    return (
            <QueerColorPickerWrapper
                ref={constraintsRef}
                whileTap={{ opacity: 1 }}    
                whileHover={{ opacity: 1 }}  
                sideFrom={sideFrom}
                sideTo={sideTo}
            >
                <SlideWrapper
                    className="slide-wrapper"
                    drag={axis}
                    dragConstraints={dragConstraints}
                    dragElastic={1}
                    dragMomentum={false}
                    style={{ [axis]: motionValue }}
                    onDrag={onDragEnd}
                >
                    <Slide color={color}/>
                </SlideWrapper>
            </QueerColorPickerWrapper>
    )
}

export default QueerColorPicker;