import { COLORS } from "../constants/colors";
import styled from 'styled-components';

const CircleButton = styled.a`
    border-radius:50%;
    background-color:${props=>props.color || COLORS.white};
    font-size:1.3rem;
    line-height:2.5rem;
    min-width:2.5rem;
    height:2.5rem;
    display:inline-block;
    text-align:center;
    cursor:pointer;
    margin-left:0.25rem;
    margin-right:0.25rem;
    color:;
    box-shadow: 0px 0px 7px 0px rgba(74,74,74,0.59);
    transition: box-shadow 400ms, opacity 600ms;
    user-select: none !important;
    color:${props=> props.colorText || COLORS.greydark} !important;

    .fas{
        color:${props=> props.colorText || COLORS.greydark};     
    }

    ${props=> props.disabled ?
    `   filter: saturate(0.4);
        opacity:0.6;
        cursor: not-allowed;
        box-shadow: none;
    `
    :`
        &:hover{
            box-shadow: 0px 0px 7px 0px rgba(74,74,74,1);
        }
        &:active{
            box-shadow: 0px 0px 7px 0px rgba(74,74,74,1) inset;
        }
    `
    }

`;

export default CircleButton