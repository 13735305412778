import './App.css';
import React from 'react';
import { Flag, getFlagState } from './flag/Flag';
import { COLORS } from './constants/colors';
import { FLAG_PRESETS, DIRECTION } from './constants/flag-presets';
import { serviceFlagMatch } from './services/serviceFlagMatch';
import html2canvas from 'html2canvas';
import { randomValue, NewGuid } from './util';
import AppSettings,{SettingsWrapper} from './settings/AppSettings';
import AboutApp from './settings/AboutApp';
import Splash from './ux/Splash';
import { AnimatePresence } from 'framer-motion';
import { FadeOut, FadeIn } from './ux/animations';
export default class App extends React.Component {

  static defaultProps = {
    splashDuration: 3000,
    maxStrips: 12
  };
  constructor(props) {
    super(props);

    let initState = {
      direction: DIRECTION.ROW,
      strips: [],
      blingLayer: false,
      blings: {},
      splash: true,
      showFeedback: false
    };
    let startingFlag = randomValue(FLAG_PRESETS);
    initState = { ...initState, ...startingFlag };
    initState.blingLayer = Object.keys(startingFlag.blings).length > 0;

    this.state = initState;

    this.flagRef = React.createRef();
  }


  componentDidMount() {
    setTimeout(this.toggleSplash, this.props.splashDuration);
  }

  render() {

    let results = serviceFlagMatch(getFlagState(this.state));

    return (

      <div className="everything">
        <AnimatePresence>
          {this.state.splash ?
            (<FadeOut key="splash">
              <Splash toggleSplash={this.toggleSplash} showFeedback={this.state.showFeedback}/>
            </FadeOut>) :
            (<FadeIn key="app">
              <div className="container-fluid p-0 h-100 no-gutter" >
                <div className="row no-gutters">
                  <div className="col-xs-12 col-md-8 col-lg-9 flag-container" ref={this.flagRef}>
                    <Flag
                      {...getFlagState(this.state)}
                      onRemoveBling={this.onRemoveBling}
                      onUpdateBling={this.onUpdateBling}
                      onColorChange={this.onColorChange}
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 col-lg-3 settings-container">
                    <div
                      className="bg-frosted"
                      style={{ backgroundColor: this.state.strips[this.state.strips.length - 1] }}>
                      &nbsp;
                    </div>
                    <SettingsWrapper>
                      <AppSettings
                        {...this.state}
                        results={results}
                        onPresetChange={this.onPresetChange}
                        onStripChange={this.onStripChange}
                        onOrientationChange={this.onOrientationChange}
                        blingLayerChange={this.blingLayerChange}
                        onSave={this.onSave.bind(this)}
                        onAddBling={this.onAddBling}
                      />
                      <AboutApp toggleSplash={this.toggleSplash} />
                    </SettingsWrapper>
                  </div>
                </div>
              </div>
            </FadeIn>
            )}
        </AnimatePresence>
      </div>
    );
  }


  toggleSplash = () => {
    this.setState({ splash: !this.state.splash, showFeedback:true});
  }

  blingLayerChange = () => {
    this.setState({ blingLayer: !this.state.blingLayer });
  }

  onOrientationChange = () => {
    this.setState({ direction: (this.state.direction === DIRECTION.ROW ? DIRECTION.COL : DIRECTION.ROW) });
  }

  onStripChange = (delta) => {
    if (delta == 1 && this.state.strips.length < this.props.maxStrips) {
      this.setState({
        strips: this.state.strips.concat(randomValue(COLORS))
      });
    }
    else if (delta < 0 && this.state.strips.length > 1) {
      let newStrips = [...this.state.strips];
      newStrips.splice(delta)
      this.setState({
        strips: newStrips
      });
    }
  }

  onColorChange = (index, color, event) => {
    let newStrips = [...this.state.strips];
    newStrips[index] = color;
    this.setState({ strips: newStrips });
  }

  onPresetChange = (e) => {

    let key = e.target.value;
    let preset = FLAG_PRESETS[key];
    let blingLayer = preset.blings.length > 0;
    this.setState({ ...preset, blingLayer });
  }

  onAddBling = (item, e) => {
    let blings = {...this.state.blings};
    blings[NewGuid()] = { ...item };
    this.setState({ blings: blings, blingLayer: true });
  }

  onRemoveBling = (instance, e) => {
    let blings = {...this.state.blings};
    delete blings[instance];
    this.setState({ blings });
  }

  onUpdateBling = (instance, e, data) => {
    const blings = {...this.state.blings};
    const bling = blings[instance];
    bling.position = {x:data.x,y:data.y};
    this.setState({blings})
  }

  onSave = () => {
    if (!this.flagRef) { return; }
    const container = this.flagRef.current;

    this.setState({ blingLayer: true }, () => {
      html2canvas(container, {
        onclone: (doc) => {
          let flag = doc.querySelector('.flag');
          let strips = doc.querySelectorAll('.strip');
          if (flag) {
            flag.className = flag.className.replace(/\bflag-animate\b/, '');
          }
          if (strips) {
            strips.forEach((strip) => { strip.innerHTML = ''; });
          }
        }
      }).then((canvas) => {
        this.downloadURI(canvas.toDataURL("image/png"), "flag.png");
      });
    });
  }

  downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}

