import React from 'react';

import { BLING_LIBRARY } from '../constants/blingLibraryItems';
import Bling from './Bling';

export default class BlingLayer extends React.PureComponent {

  static defaultProps = {
    blings: [],
    container: null,
    enabled: false
  };

  render() {
    const {blings} = this.props;

    const windowWidth = window.innerWidth;
    const OFFSETS = {
      "1x": windowWidth * 0.15 / 2.0,
      "2x": windowWidth * 0.22 / 2.0,
      "3x": windowWidth * 0.35 / 2.0,
    };

    const renderBlings = Object.keys(blings).map((instance) => {
      var item = blings[instance]
      item = {...BLING_LIBRARY[item.key],...item}

      let size = item.size || "1x";
      let position = item.position || { x: 0, y: 0 };

      if (this.props.container && position.x.toString().includes("%")) {
        position.x = parseInt(position.x) / 100.0 * this.props.container.current.offsetWidth - (OFFSETS[size] || 0);
      }
      if (this.props.container && position.y.toString().includes("%")) {
        position.y = parseInt(position.y) / 100.0 * this.props.container.current.offsetHeight - (OFFSETS[size] || 0);
      }

      return (
        <Bling
          key={instance}
          item={item}
          size={size}
          position={position}
          onRemove={this.props.onRemoveBling.bind(this, instance)}
          onUpdateBling={this.props.onUpdateBling.bind(this, instance)}
          />
      );
    });

    return (
      <div className={`layer-top ${this.props.enabled ? '':'disabled'}`}>
        {renderBlings}
      </div>
    );
  }

}