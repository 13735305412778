import '../App.css';
import './Resizable.css';
import React from 'react';
import { COLORS } from '../constants/colors';
import CircleButton from './CircleButton';
import {FadeFrom} from '../ux/animations';

export default class Resizable extends React.Component {

    static defaultProps = {
        sizes: {
            "1x": "resizable-content resizable-sm",
            "2x": "resizable-content resizable-md",
            "3x": "resizable-content resizable-lg"
        },
        size: "1x",
        btnColor: COLORS.blue,
        onResize: () => { }
    };


    constructor(props) {
        super(props);
        this.state = {
            size: this.props.size
        };
    }



    render() {
        let resizeBtns = Object.keys(this.props.sizes).map((key) => {
            return (
                <CircleButton
                key={key}
                onClick={this.onResize.bind(this, key)}
                colorText={COLORS.white}
                color={this.props.btnColor}>{key}</CircleButton>);
        });
        return (
            <div className="resizable">
                <div className={this.props.sizes[this.state.size]}>
                    {this.props.children}
                </div>
                <FadeFrom from={{y:15}} 
                show={this.props.showControls}
                style={{position:"absolute",right:0,top:0}} 
                >
                <div data-html2canvas-ignore>
                    {resizeBtns}
                </div>
                </FadeFrom>
            </div>
        );
    }

    onResize = (key, e) => {
        this.setState({ size: key });
        this.props.onResize(key, this.props.sizes[key]);
    }

}

