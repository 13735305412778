import React from 'react';
import BlingLibrary from './BlingLibrary';
import FlagSettings from './FlagSettings';
import SaveSettings from './SaveSettings';
import Presets from './Presets';
import Results from './Results';
import Toggle from '../ux/Toggle';
import { COLORS } from '../constants/colors';
import styled from 'styled-components';


export const SettingsWrapper = styled.div`
    padding:1rem;    
    margin-bottom:5rem;
    overflow-y:scroll;
    overflow-x:hidden;
    height:100%;
    display:flex;
    flex-direction:column;
`;

export const SettingsSection = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    font-size:1.5rem;
    margin-bottom: 1rem;
    flex-direction:${props=>props.direction || "row"}
    @media(min-width:768px){
        margin-bottom: 2rem;
    }
`;

export default class AppSettings extends React.PureComponent {

    static defaultProps = {
        maxStrips: 10
    };

    render() {
        const {
            blingLayer,
        } = this.props;

        return (
            <>
                <SaveSettings {...this.props} />
                <FlagSettings {...this.props} />

                <SettingsSection direction="column">
                    <a className="d-flex align-items-center"
                        onClick={this.props.blingLayerChange}
                    >
                        <span className="mr-2">Colors</span>
                        <Toggle enable={blingLayer} color={COLORS.purple} />
                        <span className="ml-2">Bling</span>
                    </a>
                    {blingLayer &&
                    <BlingLibrary {...this.props} />
                    }
                </SettingsSection>
                
            
                <Presets {...this.props} />
                <Results {...this.props} />
            </>
        );
    }

}