
import React from 'react';
import { motion } from "framer-motion";



export const FadeIn = (props) => (
    <motion.div
      initial={{opacity:0}}
      animate={{ opacity:1 }}
      exit={{ opacity:1 }}
      transition={{ duration: props.duration || 1 }}
      {...props}
    />
);

export const FadeFrom = (props) => {

  const variants = {
    hidden: { ...props.from, opacity: 0,},
    show: { opacity: 1,y:0,x:0 },
  }
  
  return (
  <motion.div
    initial={variants.hidden}
    animate={props.show?"show":"hidden"}
    variants={variants}
    transition={{ duration: props.duration || 1 }}
    {...props}
  />)
};

export const FadeOut = (props) => (
  <motion.div
    initial={{opacity:1}}
    animate={{ opacity:1 }}
    exit={{opacity:0}}
    transition={{ duration: props.duration || 1 }}
    {...props}
  />
);
