import React from 'react';
import { COLORS } from '../constants/colors';
import CircleButton from '../ux/CircleButton';
import * as chromatism from 'chromatism';
import {SettingsSection} from './AppSettings';

const FlagSettings = (props) => (
    <SettingsSection>
        <div>Stripes</div>
        <div>
        <CircleButton
            title="Remove Stripe"
            onClick={props.onStripChange.bind(this, -1)}
            disabled={props.strips.length <= 1}
            color={chromatism.brightness(20,COLORS.cyan).hex}
        >
            <i className="fas fa-minus"></i>
        </CircleButton>

        <CircleButton
            title="Add Stripe"
            onClick={props.onStripChange.bind(this, 1)}
            className={props.strips.length >= props.maxStrips}
            color={chromatism.brightness(20,COLORS.pink).hex}
        >
        <i className="fas fa-plus"></i>
        </CircleButton>

        <CircleButton
            title="Change Stripe Orientation"
            onClick={props.onOrientationChange}
            color={chromatism.brightness(20,COLORS.green).hex}
        >
            <i className="fas fa-sync-alt"></i>
        </CircleButton>
        </div>
    </SettingsSection>);

export default FlagSettings;