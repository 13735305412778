import { COLORS } from "../constants/colors";
import {uniq,difference} from 'lodash';
import {FLAG_PRESETS,DIRECTION} from '../constants/flag-presets';
import {dudupeSimilarAdjacent, colorDifferenceHex} from '../util';

//difference threshold
var threshold = 102; //255*sqrt(2)^2*0.2 //PLAY WITH ME

//singleton for calculating the blended versions of all presets
export const FLAG_PRESET_BLENDS = (function () {
    var instance;
 
    function createInstance() {
        return Object.keys(FLAG_PRESETS).reduce((acc,key)=>{
            acc[key] = dudupeSimilarAdjacent(FLAG_PRESETS[key].strips,colorDifferenceHex,threshold);
            return acc;
        },{})
    }
 

    if (!instance) {
        instance = createInstance();
    }
    return instance;
        
    
})();

export const serviceFlagMatch = (flagObject) =>{
    
    let results = [];
    
    //colors that are similar based on euclidian threshold should be deduped
    let thisBlend = dudupeSimilarAdjacent(flagObject.strips,colorDifferenceHex,threshold);

    //go through presetes
    Object.keys(FLAG_PRESETS).forEach((key)=>{
        let preset = FLAG_PRESETS[key];
        let presentBlend = FLAG_PRESET_BLENDS[key];
        let difference = 100000; //we want a short distance so start off high

        //if the flags are similar in different kinds of strips
        if(presentBlend.length === thisBlend.length){
            difference = 0;
            //console.log('comparison',thisBlend,presentBlend);
            for(let i=0;i<presentBlend.length;i++){
                difference+= colorDifferenceHex(presentBlend[i],thisBlend[i]);
            }
            difference = difference/presentBlend.length;
            //TODO: take into account flipped
        }

        //take into account direction and if they are flipped, reduce by 50%
        if(preset.direction !== flagObject.direction){
            difference = difference * 5.0; //PLAY WITH ME
        }

        if(flagObject.blings.length){
            let thisBlings = flagObject.blings.map((each)=>{return each.key;});
            (preset.blings || []).forEach((presetBling)=>{
                if(thisBlings.indexOf(presetBling.key) >= 0){
                    difference = difference * 0.3; //PLAY WITH ME
                }
            });
        }

        results.push([key,difference]);
    });

    //console.log('results',flagObject,results);

    results.sort(function(a,b){return a[1] - b[1];});

    return results;
}