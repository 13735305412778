import * as chromatism from 'chromatism';

export const saturation = 86;
export const value = 91;
const HueToColor = (hue)=> {
    return chromatism.convert({h:hue,s:saturation,v:value}).hex;
};

export const COLORS = {
    
    red:        HueToColor(0),
    brown:      HueToColor(10),
    orange:     HueToColor(25),
    darkyellow: HueToColor(40),
    yellow:     HueToColor(58),
    yellowgreen:HueToColor(83),
    green:      HueToColor(108),
    cyan:       HueToColor(176),
    lightblue:  HueToColor(198),
    blue:       HueToColor(230),
    darkpurple: HueToColor(259),
    purple:     HueToColor(274),
    lightpink:  HueToColor(300),
    pink:       HueToColor(316),
    razzmatazz: HueToColor(337),

    black:      "#111111",
    greydark:   "#444444",
    grey:       "#999999",
    offwhite:   "#cccccc",
    white:      "#ffffff",
};
