import React from 'react';
import QueerColorPicker from './QueerColorPicker';

export default class FlagStrip extends React.PureComponent {
  
  static defaultProps = {
    color: "#fff",
    index: -1,
    direction: null,
    onChange: () => { },
    showPicker:true
  };

  render() {
    return (<div
      style={{ 
        backgroundColor: this.props.color,
        animationDuration:this.props.index*100 + "ms",
        flexBasis:0,
        flexGrow:1
      }}>
      {this.props.showPicker && <QueerColorPicker
        width="100%"
        height="100%"
        direction={this.props.direction}
        color={this.props.color}
        onChange={this.props.onChange.bind(this,this.props.index)}
      />}
    </div>);
  }

}