import React, { useState } from 'react';
import { motion, useMotionValue } from "framer-motion";
import styled from 'styled-components';
import { COLORS } from '../constants/colors';
import Draggable from 'react-draggable';
import Rotatable from '../ux/Rotatable';
import Resizable from '../ux/Resizable';
import CircleButton from '../ux/CircleButton';
import {FadeFrom} from '../ux/animations';


const RemoveButtonStyling = styled.div`
    position:absolute;
    z-index:100;
`;
const RemoveButton = (props) => (
    <RemoveButtonStyling>
    <FadeFrom from={{y:15}} show={props.showControls}>
        <CircleButton data-html2canvas-ignore
            className="position-absolute"
            onClick={props.onClick}
            colorText={COLORS.white}
            color={COLORS.red}
        ><i className="fas fa-times"></i></CircleButton>
    </FadeFrom>
    </RemoveButtonStyling>
);

const Bling = (props) => {
    const { item, onRemove, size, position, onUpdateBling } = props;
    const [showControls, setShowControls] = useState(false);


    return (<Draggable
        position={position}
        bounds="parent"
        onMouseDown={(event) => { if (event.preventDefault) event.preventDefault() }}//ff hack
        onStop={onUpdateBling}
        >
        <div className="position-absolute"
        onMouseEnter={()=>{setShowControls(true)}}
        onMouseLeave={()=>{setShowControls(false)}}>
            <RemoveButton
                onClick={onRemove}
                showControls={showControls}
            />
            <Resizable size={size} showControls={showControls}>
                <Rotatable angle={item.angle || 0} showControls={showControls}>
                    <img className="bling" src={item.src} />
                </Rotatable>
            </Resizable>
        </div>
    </Draggable>);



};

export default Bling;