import React from 'react';
import './Flag.css';
import FlagStrip from './FlagStrip';
import BlingLayer from './BlingLayer';
import {DIRECTION} from '../constants/flag-presets';


export const getFlagState = (state) => {
  return {
    direction: state.direction,
    strips: state.strips,
    blingLayer: state.blingLayer,
    blings: state.blings
  }
};

export class Flag extends React.Component {
  static defaultProps = {
    canton: null,
    direction: DIRECTION.ROW,
    strips: [],
    blings: [],
    showPicker: true,
    animateClass: "flag-animate",
    blingLayer: true,
    onRemoveBling: () => { },
    className: ''
  };

  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      container: null
    };
  }

  componentDidMount() {
    this.setState({ container: this.container });
  }

  render() {
    let containerClassName = `flag ${this.props.animateClass} ${this.props.className} ${this.props.direction === DIRECTION.ROW ? 'strip-container-rows': 'strip-container-cols'} `;
    
    const strips = this.props.strips.map((color, index) => {
      return (<FlagStrip
        key={index}
        index={index}
        color={color}
        direction={this.props.direction}
        onChange={this.props.onColorChange}
        showPicker={this.props.showPicker}
      />)
    });


    return (
      <div ref={this.container} className={containerClassName}>
        {this.state.container &&
          <BlingLayer
            {...this.props}
            container={this.state.container}
            enabled={this.props.blingLayer}
          />
        }
        {strips}
      </div>
    );
  }


}