import React from 'react';
import CircleButton from '../ux/CircleButton';
import { SettingsSection } from './AppSettings';

const SaveSettings = (props) => (
    <SettingsSection>
        <div>Save (Experimental)</div>
        <CircleButton
            title="Download"
            onClick={props.onSave}>
            <span className="fas fa-download"></span>
        </CircleButton>
    </SettingsSection>);

export default SaveSettings;