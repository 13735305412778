import * as chromatism from 'chromatism';

export function randomValue(dict){
    let values = Object.values(dict);
    return values[Math.floor(Math.random() * values.length)];
}

export function NewGuid(){
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
export function dudupeSimilarAdjacent(arr, differenceFxt, threshold){
    let result = []
    arr.forEach((each)=>{
        let last = result[result.length-1];
        if(result.length === 0 || differenceFxt(last,each) >= threshold){
            result.push(each);
        }
    });
    return result;
}

export function dedupeAdjacent(arr){
    let result = []
    arr.forEach((each)=>{
        let last = result[result.length-1];
        if(result.length === 0 || last !== each){
            result.push(each);
        }
    });
    return result;
}

export function colorDifferenceHex(hex1,hex2){
    return vectorDifference3(
        Object.values(chromatism.convert(hex1).rgb),
        Object.values(chromatism.convert(hex2).rgb)
    );
}

export function vectorDifference3(v1,v2){
    let i, d = 0;

    for (i = 0; i < v1.length; i++) {
        d += (v1[i] - v2[i])*(v1[i] - v2[i]);
    }
    return Math.abs(Math.sqrt(d));
}
