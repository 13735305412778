import { COLORS } from './colors';

export const DIRECTION = {
    ROW: "ROW",
    COL: "COL"
};

export const FLAG_PRESETS = {
    gbPride: {
        name: "Gilbert Baker Pride Flag",
        strips: [
            COLORS.lightpink,
            COLORS.red,
            COLORS.orange,
            COLORS.yellow,
            COLORS.green,
            COLORS.cyan,
            COLORS.blue,
            COLORS.darkpurple
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    milkPride: {
        name: "Harvey Milk 1978 Pride Flag",
        strips: [
            COLORS.red,
            COLORS.orange,
            COLORS.yellow,
            COLORS.green,
            COLORS.blue,
            COLORS.darkpurple,
            COLORS.purple
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    tradPride: {
        name: "Traditional Pride Flag",
        strips: [
            COLORS.red,
            COLORS.orange,
            COLORS.yellow,
            COLORS.green,
            COLORS.blue,
            COLORS.darkpurple
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    phillyPoc: {
        name: "Philadelphia POC Flag",
        strips: [
            COLORS.black,
            COLORS.brown,
            COLORS.red,
            COLORS.orange,
            COLORS.yellow,
            COLORS.green,
            COLORS.blue,
            COLORS.purple
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    bi: {
        name: "Bisexual Flag",
        strips: [
            COLORS.razzmatazz,
            COLORS.purple,
            COLORS.blue
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    pan: {
        name: "Pansexual Flag",
        strips: [
            COLORS.pink,
            COLORS.yellow,
            COLORS.cyan
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    ace: {
        name: "Asexual Flag",
        strips: [
            COLORS.black,
            COLORS.grey,
            COLORS.white,
            COLORS.darkpurple
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    labrys: {
        name: "Labrys Lesbian Pride Flag",
        strips: [
            COLORS.darkpurple
        ],
        direction: DIRECTION.ROW,
        blings: {"llpf":
                    { key: "axe",position: { x: "50%", y: "50%" },size: "3x" }
                }
    },
    poly: {
        name: "Polyamory Flag",
        strips: [
            COLORS.blue,
            COLORS.red,
            COLORS.black,
        ],
        direction: DIRECTION.ROW,
        blings: {"poly":
                    { key: "pi", position: { x: "50%", y: "50%" } }
                }
    },
    intersex: {
        name: "Intersex Flag",
        strips: [
            COLORS.yellow,
        ],
        direction: DIRECTION.ROW,
        blings: {"intersex":
                    { key: "ring", position:{ x: "50%", y: "50%" },size: "2x" }
                }
    },
    trans: {
        name: "Transgender Flag",
        strips: [
            COLORS.cyan,
            COLORS.lightpink,
            COLORS.white,
            COLORS.lightpink,
            COLORS.cyan,
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    fluid: {
        name: "Genderfluid Flag",
        strips: [
            COLORS.pink,
            COLORS.white,
            COLORS.purple,
            COLORS.black,
            COLORS.blue,
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    queer: {
        name: "Genderqueer Flag",
        strips: [
            COLORS.purple,
            COLORS.white,
            COLORS.green
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    lipstick: {
        name: "Lipstick Lesbian Flag",
        strips: [
            COLORS.darkpurple,
            COLORS.purple,
            COLORS.razzmatazz,
            COLORS.white,
            COLORS.pink,
            COLORS.red,
            COLORS.brown,
        ],
        direction: DIRECTION.ROW,
        blings: {"lipstick":
                    { key: "lips", position:{ x: "20%", y: "30%" }, size: "1x" }
                }
    },
    bdsm: {
        name: "BDSM Flag",
        strips: [
            COLORS.black,
            COLORS.blue,
            COLORS.black,
            COLORS.blue,
            COLORS.white,
            COLORS.blue,
            COLORS.black,
            COLORS.blue,
            COLORS.black,
        ],
        direction: DIRECTION.ROW,
        blings: {"bdsm":
                    { key: "heart", position: { x: "20%", y: "30%" }, size: "1x", angle: 360 - 30 }
                }
    },
    bear: {
        name: "Bear Brotherhood Flag",
        strips: [
            COLORS.brown,
            COLORS.orange,
            COLORS.darkyellow,
            COLORS.yellow,
            COLORS.white,
            COLORS.grey,
            COLORS.black
        ],
        direction: DIRECTION.ROW,
        blings: {"bear":
            { key: "bear", position: { x: "25%", y: "25%" }, size: "1x", angle: 360 - 30 }
        }
    },
    rubber: {
        name: "Rubber Pride Flag",
        strips: [
            COLORS.black,
            COLORS.red,
            COLORS.yellow,
            COLORS.red,
            COLORS.black,
            COLORS.red,
            COLORS.black
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    polysex: {
        name: "Polysexual Flag",
        strips: [
            COLORS.pink,
            COLORS.green,
            COLORS.cyan
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    agender: {
        name: "Agender Flag",
        strips: [
            COLORS.black,
            COLORS.grey,
            COLORS.white,
            COLORS.green,
            COLORS.white,
            COLORS.grey,
            COLORS.black,
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    aromantic: {
        name: "Aromantic Flag",
        strips: [
            COLORS.green,
            COLORS.yellowgreen,
            COLORS.white,
            COLORS.grey,
            COLORS.black,
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    nonbinary: {
        name: "Nonbinary Flag",
        strips: [
            COLORS.yellow,
            COLORS.white,
            COLORS.purple,
            COLORS.black,
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    demisexual: {
        name: "Demisexual Flag",
        strips: [
            COLORS.white,
            COLORS.white,
            COLORS.darkpurple,
            COLORS.grey,
            COLORS.grey
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    demigender: {
        name: "Demigender Flag",
        strips: [
            COLORS.grey,
            COLORS.offwhite,
            COLORS.yellow,
            COLORS.white,
            COLORS.yellow,
            COLORS.offwhite,
            COLORS.grey
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    demiboy: {
        name: "Demiboy Flag",
        strips: [
            COLORS.grey,
            COLORS.offwhite,
            COLORS.lightblue,
            COLORS.white,
            COLORS.lightblue,
            COLORS.offwhite,
            COLORS.grey
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    demigirl: {
        name: "Demigirl Flag",
        strips: [
            COLORS.grey,
            COLORS.offwhite,
            COLORS.lightpink,
            COLORS.white,
            COLORS.lightpink,
            COLORS.offwhite,
            COLORS.grey
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    fluidflux: {
        name: "Fluidflux Flag",
        strips: [
            COLORS.red,
            COLORS.purple,
            COLORS.cyan,
            COLORS.yellow,
            COLORS.black,
            COLORS.yellow,
            COLORS.lightblue,
            COLORS.lightpink,
            COLORS.pink

        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    polygender: {
        name: "Polygender Flag",
        strips: [
            COLORS.black,
            COLORS.grey,
            COLORS.pink,
            COLORS.yellow,
            COLORS.cyan
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    trigender: {
        name: "Trigender Flag",
        strips: [
            COLORS.lightpink,
            COLORS.purple,
            COLORS.green,
            COLORS.purple,
            COLORS.lightpink
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    maverique: {
        name: "Maverique Flag",
        strips: [
            COLORS.yellow,
            COLORS.white,
            COLORS.orange
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    intergender: {
        name: "Intergender Flag",
        strips: [
            COLORS.lightpink,
            COLORS.white,
            COLORS.purple,
            COLORS.grey,
            COLORS.purple,
            COLORS.white,
            COLORS.lightblue,
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    androgyne: {
        name: "Androgyne Flag",
        strips: [
            COLORS.black,
            COLORS.lightblue,
            COLORS.white,
            COLORS.purple,
            COLORS.white,
            COLORS.lightpink,
            COLORS.black,
        ],
        direction: DIRECTION.ROW,
        blings: {}
    },
    genderflux: {
        name: "Genderflux Flag",
        strips: [
            COLORS.pink,
            COLORS.lightpink,
            COLORS.grey,
            COLORS.cyan,
            COLORS.lightblue,
            COLORS.yellow
        ],
        direction: DIRECTION.ROW,
        blings: {}
    }
};
