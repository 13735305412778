import React from 'react';
import { FLAG_PRESETS } from '../constants/flag-presets';
import { SettingsSection } from './AppSettings';
const Presets = (props) => {


    let presets = Object.keys(FLAG_PRESETS).map(key => {
        return (<option key={key} value={key}>{FLAG_PRESETS[key].name}</option>);
    });
    presets.sort((a, b) => {
        var nameA = a.props.children.toUpperCase();
        var nameB = b.props.children.toUpperCase();
        if (nameA < nameB) { return -1; } if (nameA > nameB) { return 1; } return 0;
    });

    return (
        <SettingsSection>
            <div className="pr-2">Presets</div>
            <select
                className="form-control my-0"
                onChange={props.onPresetChange}>
                <option selected>-- Select Preset --</option>
                {presets}
            </select>
        </SettingsSection>
    );
};

export default Presets;